<mat-toolbar>
	<button mat-icon-button (click)="editor.toggleFullLock()">
		<mat-icon>{{ editor.hasFullLock() ? 'lock' : 'lock_open' }}</mat-icon>
	</button>
	<span [style.flex]="'1 1 auto'">{{ editor.boType }} {{ editor.bo?.getQualifiedName() }}</span>
	<button mat-icon-button (click)="editor.save()" *ngIf="editor.bo?.isModifiable">
		<mat-icon>save</mat-icon>
	</button>
</mat-toolbar>

<div class="content">
	<ng-content></ng-content>
</div>