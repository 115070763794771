import { AuthInterceptor } from '@ng-shared/lib/services/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockNamePipe } from './pipes/block-name.pipe';
import { EditModule } from './edit/edit.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedStudioModule } from './shared/shared.studio.module';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorFileNotFoundComponent } from './shared/error-file-not-found/error-file-not-found.component';
import { NoStudioAccessComponent } from './shared/no-studio-access/no-studio-access';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router'
import { StudioRouteReuseStrategy } from './studio-route-reuse-strategy'
import { LoginComponent } from './components/login/login.component'
import { AppTrpcServiceInjectionToken } from '@ng-shared/lib/services/app-trpc.service'
import { StudioTrpcService } from '@ng-shared/lib/services/studio-trpc.service'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'

@NgModule({
  declarations: [
    AppComponent,
    ErrorFileNotFoundComponent,
    BlockNamePipe,
    NoStudioAccessComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedStudioModule,
    AppRoutingModule,
    EditModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: AppTrpcServiceInjectionToken, useExisting: StudioTrpcService },
    // { provide: RouteReuseStrategy, useClass: StudioRouteReuseStrategy },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
