import type { EventType, HtmlType } from './../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { Expression } from '@shared/data/text-or-code'
import { Block } from '..'

@jsonObject({ name: 'RichTextEditorBlock' })
export class RichTextEditorBlock extends Block {
	@jsonMember(Expression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Binding',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => true,
	})
	bindingCode = new Expression()

	constructor(init: Partial<RichTextEditorBlock> = {}) {
		super()
		this.init(init)
	}

	get allowedEventTypes(): EventType[] {
		return [
			{ name: 'keydown' },
			{ name: 'keyup' },
			{ name: 'change' },
		]
	}

	canHaveChildren(): boolean {
		return false
	}

	producePreview(): HtmlType {
		return `<textarea tabindex="-1" style="pointer-events: none;">${this.calculateExampleText()}</textarea>`
	}

	getEditorCategory(): string {
		return 'Additional Form Fields'
	}
}
