import { Observable, of } from 'rxjs'
import { tap } from 'rxjs/operators'

export class LazyCache<A extends string, K, V>{
	private cache = new Map<A, WeakMap<object, V>>()
	private nonObjectKeySymbols = new Map<K, object>()

	private getAreaCache(area: A, createIfNotExists: true): WeakMap<object, V>
	private getAreaCache(area: A, createIfNotExists?: boolean): WeakMap<object, V> | undefined
	private getAreaCache(area: A, createIfNotExists = true) {
		let areaCache = this.cache.get(area)
		if(!areaCache && createIfNotExists) {
			areaCache = new WeakMap()
			this.cache.set(area, areaCache)
		}
		return areaCache
	}

	private getKey(key: K): object {
		if(key && typeof key == 'object') return key
		if(!this.nonObjectKeySymbols.has(key)) {
			this.nonObjectKeySymbols.set(key, {})
		}
		return this.nonObjectKeySymbols.get(key)!
	}

	set(area: A, key: K, value: V) {
		const keyObj = this.getKey(key)
		this.getAreaCache(area, true).set(keyObj, value)
	}

	get(area: A, key: K, calculationFunc: (key: K) => V, forceReload = false): V {
		const keyObj = this.getKey(key)
		const areaCache = this.getAreaCache(area)
		if(areaCache?.has(keyObj) && !forceReload) return areaCache.get(keyObj)!

		try {
			const areaCache = this.getAreaCache(area, true)
			const value = calculationFunc(key)
			areaCache.set(keyObj, value)

			return value
		} catch(err: any) {
			console.error(err?.message ?? err)
		}
		return undefined!
	}

	get$(area: A, key: K, value$?: Observable<V>, forceReload = false) {
		const keyObj = this.getKey(key)
		const areaCache = this.getAreaCache(area)
		if(areaCache?.has(keyObj) && !forceReload) return of(areaCache.get(keyObj))
		if(!value$) return of(undefined)

		return value$.pipe(
			tap(value => {
				const areaCache = this.getAreaCache(area, true)
				areaCache.set(keyObj, value)
			})
		)
	}

	has(area: A, key: K) {
		const keyObj = this.getKey(key)
		const areaCache = this.getAreaCache(area)
		return areaCache?.has(keyObj) ?? false
	}

	clearArea(area: A) {
		this.cache.delete(area)
	}

	clearItem(area: A, key: K) {
		const keyObj = this.getKey(key)
		const areaCache = this.getAreaCache(area, false)
		areaCache?.delete(keyObj)
	}

	clear() {
		this.cache.clear()
	}
}