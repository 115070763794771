import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Block } from '@shared/blocks'
import { EditorWizardData } from '@shared/blocks/decorators/editor-property-types'
import { IconBlock, IconBlockFontSet, IconBlockFontSets } from '@shared/blocks/other/icon-block'
import { Screen } from '@shared/bos'
import type { MaterialIconDefinition } from '@shared/util/material-icons'
import { BehaviorSubject, Subject, combineLatest, debounceTime, distinctUntilChanged, map, startWith } from 'rxjs'
import { EditCommonModule } from '../../edit-common/edit-common.module'
import { ButtonBlock } from '@shared/blocks/form/button-block'

@Component({
	selector: 'lowgile-icon-picker',
	templateUrl: './icon-picker.component.html',
	styleUrls: ['./icon-picker.component.scss'],
	standalone: true,
	imports: [
		EditCommonModule,
	]
})
export class IconPickerComponent implements OnInit {
	protected block: Block
	protected fontSet: IconBlockFontSet
	private allIconsSubject = new Subject<MaterialIconDefinition[]>()
	protected filterSubject = new BehaviorSubject('')
	protected icons$ = combineLatest([
		this.allIconsSubject,
		this.filterSubject.pipe(
			debounceTime(250),
			startWith(''),
			distinctUntilChanged(),
		),
	]).pipe(
		map(([allIcons, filter]) => {
			filter = filter.trim()

			const exactMatches = allIcons.filter(icon => icon.name.includes(filter))
			const approximateMatches = filter ? allIcons.filter(icon => {
				if(exactMatches.includes(icon)) return false
				for(const tag of icon.tags) {
					if(tag.includes(filter)) return true
				}
				for(const category of icon.categories) {
					if(category.includes(filter)) return true
				}
				
				return false
			}) : []

			return {
				exact: new Set(exactMatches.map(icon => icon.name)),
				approximate: new Set(approximateMatches.map(icon => icon.name)),
			}
		})
	)
	protected fontSets = IconBlockFontSets
	
	
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: EditorWizardData<Screen, Block>,
	) {
		this.readFromBlock()
	}

	protected readFromBlock() {
		const block = this.data.item
		if(!(block instanceof IconBlock)) throw new Error('Invalid block type')
		this.block = block
		this.fontSet = block.fontSet
	}

	protected writeToBlock(iconName: string) {
		const block = this.block
		if(!(block instanceof IconBlock)) throw new Error('Invalid block type')
		block.textCode.set(iconName, 'T')
		block.fontSet = this.fontSet
	}

	async ngOnInit() {
		const { MaterialIcons } = await import('@shared/util/material-icons')
		this.allIconsSubject.next(MaterialIcons)
	}
	
	onSelect(iconName: string) {
		this.writeToBlock(iconName)
	}
}

@Component({
	selector: 'lowgile-prefix-icon-picker',
	templateUrl: './icon-picker.component.html',
	styleUrls: ['./icon-picker.component.scss'],
	standalone: true,
	imports: [
		EditCommonModule,
	]
})

export class PrefixIconPickerComponent extends IconPickerComponent {
	protected readFromBlock() {
		const block = this.data.item
		if(!(block instanceof ButtonBlock)) throw new Error('Invalid block type')
		this.block = block
		this.fontSet = block.prefixIconFontSet
	}

	protected writeToBlock(iconName: string) {
		const block = this.block
		if(!(block instanceof ButtonBlock)) throw new Error('Invalid block type')
		block.prefixIconCode.set(iconName, 'T')
		block.prefixIconFontSet = this.fontSet
	}
}