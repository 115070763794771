import { DataUtil } from '@shared/util/data-util'
import { UserIdCtor, type UserIdType } from '@shared/types'
import { JsonMappable } from '@shared/data/json-mappable'
import { jsonObject, jsonMember, jsonArrayMember, toJson } from 'typedjson'
import { UserIdpRole, UserRole } from '@shared/auth/user-role'
import { UserPassword } from '@shared/auth/user-password'
import type { SystemRoleName } from '@shared/auth/system-roles'
import { UserPermission } from './user-permission'
import { SystemPermissionName } from './system-permissions'
import { UserRecord, UserRecordOptions, UserRecordUpdate } from './user-record'
import { UserConfiguration } from './user-configuration'

export const UserStatuses = ['active', 'passwordChangeNeeded', 'blocked', 'inactive', 'deleted'] as const
export type UserStatus = typeof UserStatuses[number]

@jsonObject
@toJson
export class User implements JsonMappable, Required<UserRecordUpdate> {
	@jsonMember(String)
	get __type(): string { return 'User' }
	set __type(_) { /*ignore*/ }

	@jsonMember(() => UserIdCtor)
	id: UserIdType
	
	@jsonMember(String)
	userName: string = ''
	
	@jsonMember(String)
	firstName: string = ''
	
	@jsonMember(String)
	lastName: string = ''

	@jsonMember(String)
	email: string = ''

	@jsonMember(String)
	mobilePhone: string = ''
	
	@jsonMember
	creationTimestamp: Date = new Date()
	
	@jsonMember
	updateTimestamp: Date = new Date()
	
	@jsonMember
	lastLogin?: Date

	// @jsonMember(() => UserPassword)
	password?: UserPassword

	@jsonArrayMember(() => UserRole)
	roles?: UserRole[]
	@jsonArrayMember(() => UserIdpRole)
	idpRoles?: UserIdpRole[]

	@jsonArrayMember(() => UserPermission)
	permissions?: UserPermission[]
	
	@jsonArrayMember(() => UserConfiguration)
	configurations?: UserConfiguration[]

	@jsonArrayMember(String)
	groups?: string[]

	@jsonMember(String)
	status: UserStatus = 'active'
	
	@jsonMember(Number)
	failedLoginAttempts: number = 0


	@jsonArrayMember(() => UserRole)
	effectiveRoles?: UserRole[]
	@jsonArrayMember(() => UserPermission)
	effectivePermissions?: UserPermission[]
	@jsonMember(String)
	identityProviderId?: string

	get systemRoles() {
		return (this.effectiveRoles ?? [...this.roles!, ...this.idpRoles!])?.filter(r => r.roleModuleId == 'System') ?? []
	}

	get systemPermissions() {
		return (this.effectivePermissions ?? this.permissions)?.filter(p => p.permissionModuleId == 'System') ?? []
	}

	constructor(init?: Partial<User>) {
		DataUtil.assignCommonProperties(this, init)
	}

	init() {
		if(!this.roles) this.roles = []
		if(!this.permissions) this.permissions = []
	}

	deepClone(): User {
		return new User({
			...this,
			roles: this.roles?.map(role => new UserRole(role)) ?? [],
			idpRoles: this.idpRoles?.map(role => new UserIdpRole(role)) ?? [],
			password: new UserPassword(this.password)
		})
	}

	getName() {
		return `${this.firstName} ${this.lastName}`
	}

	getRoleNames(filterForTenantId?: string) {
		let roles = this.effectiveRoles ?? [...this.roles!, ...this.idpRoles!] ?? []
		if(filterForTenantId) roles = roles.filter(r => r.tenantIds.includes(filterForTenantId))
		const roleNames = roles.map(role => role.getQualifiedName()) ?? []
		return [...new Set(roleNames)]
	}

	private hasRoleInternal(role: UserRole | string, roleList: UserRole[]) {
		if(typeof role == 'string') role = UserRole.fromQualifiedName(role)
		return roleList?.some(r => r.matches(role))
	}

	hasEffectiveRole(role: UserRole | string) {
		return this.hasRoleInternal(role, this.effectiveRoles ?? this.roles ?? [])
	}

	hasDirectRole(role: UserRole | string) {
		return this.hasRoleInternal(role, this.roles ?? [])
	}

	hasIdpRole(role: UserIdpRole | string) {
		return this.hasRoleInternal(role, this.idpRoles ?? [])
	}

	hasSystemRole(roleName: SystemRoleName) {
		const roles = this.effectiveRoles ?? [...this.roles!, ...this.idpRoles!] ?? []
		return !!roles.find(role => role.roleModuleId == 'System' && role.roleBoId == roleName)
	}

	getRoleInheritanceSources(role: UserRole) {
		if(this.hasDirectRole(role) || !this.hasEffectiveRole(role)) return []

		const sources: string[] = []
		if(this.hasIdpRole(role)) sources.push('From identity provider')
		if(!sources.length) sources.push('From other roles')
		return sources
	}

	getPermissionNames(filterForTenantId?: string) {
		let permissions = this.effectivePermissions ?? this.permissions ?? []
		if(filterForTenantId) permissions = permissions.filter(r => r.tenantIds.includes(filterForTenantId))
		const permissionNames = permissions.map(permission => permission.getQualifiedName()) ?? []
		return [...new Set(permissionNames)]
	}

	hasDirectPermission(permission: UserPermission | string) {
		if(typeof permission == 'string') permission = UserPermission.fromQualifiedName(permission)
		const permissions = this.permissions ?? []
		return !!permissions.some(p => p.matches(permission))
	}

	hasEffectivePermission(permission: UserPermission | string) {
		if(typeof permission == 'string') permission = UserPermission.fromQualifiedName(permission)
		const permissions = this.effectivePermissions ?? this.permissions ?? []
		return !!permissions.some(p => p.matches(permission))
	}
	hasPermission(permission: UserPermission | string) {
		return this.hasEffectivePermission(permission)
	}

	hasSystemPermission(permissionName: SystemPermissionName) {
		const permissions = this.effectivePermissions ?? this.permissions ?? []
		return !!permissions.find(permission => permission.permissionModuleId == 'System' && permission.permissionBoId == permissionName)
	}

	isActive() {
		return this.status == 'active'
	}

	canLogin() {
		return this.status == 'active' || this.status == 'passwordChangeNeeded'
	}

	canResetPassword() {
		return this.canLogin() || this.status == 'blocked'
	}

	isInGroup(groupName: string) {
		return this.groups?.includes(groupName) ?? false
	}

	mapToUserRecord(options?: UserRecordOptions) {
		if(options == '*') {
			options = {
				includeEmail: true,
				includeGroups: true,
				includePermissions: true,
				includePhone: true,
				includeRoles: true
			}
		}
		
		return new UserRecord({
			...this,
			email: options?.includeEmail ? this.email : undefined,
			mobilePhone: options?.includePhone ? this.mobilePhone : undefined,
			roleNames: options?.includeRoles ? this.getRoleNames() : [],
			permissionNames: options?.includePermissions ? this.getPermissionNames() : [],
			groupNames: options?.includeGroups ? this.groups ?? [] : [],
		})
	}

	cloneWithoutPassword() {
		return new User({
			...this,
			password: undefined,
		})
	}
}