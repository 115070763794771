import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'
import { DataUtil } from '@shared/util/data-util'
import { OpenAPIV3 } from "openapi-types";
import { ServiceProviderVersion } from './service-provider-version'
import { ServiceProviderMethod } from './service-provider-method'

export const ServiceProviderAuthTypes = {
	apiKey: 'API key',
	basic: 'Basic (username & password)',
	oauth2: 'OAuth2',
}
export type ServiceProviderAuthType = keyof typeof ServiceProviderAuthTypes

@jsonObject({ name: 'ServiceProvider' })
export class ServiceProvider extends BusinessObject {
	constructor(init?: Partial<ServiceProvider>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	@jsonArrayMember(ServiceProviderVersion)
	versions: ServiceProviderVersion[] = [new ServiceProviderVersion({ versionName: 'v1' })]
	@jsonArrayMember(ServiceProviderMethod)
	methods: ServiceProviderMethod[] = []
	@jsonMapMember(String, Boolean)
	allowedAuthTypes = new Map<string, boolean>()

	getClassNames() {
		return [this.getQualifiedName()]
	}
}


let exampleService: OpenAPIV3.Document = {
	openapi: "3.0.0",
	info: {
		title: "Example API",
		version: "1.0.0"
	},
	paths: {
		"/list": {
			get: {
				parameters: [
					{
						name: "limit",
						in: "query",
						required: false,
						schema: {
							type: "integer"
						}
					}
				],
				responses: {
					"200": {
						description: "A list of items",
						content: {
							"application/json": {
								schema: {
									type: "array",
									items: {
										$ref: "#/components/schemas/Item"
									}
								}
							}
						}
					}
				}
			}
		}
	},
	components: {
		schemas: {
			Item: {
				type: "object",
				properties: {
					id: {
						type: "integer",
						format: "int64"
					},
					name: {
						type: "string"
					}
				}
			}
		}
	}
}