import { FormEditorComponent } from '../screen-editor/form-editor/form-editor.component';
import { EditorWizardDialog } from '@shared/blocks/decorators/editor-property-types';
import { Injectable, Injector, inject } from '@angular/core'
import { BusinessObjectFilterType } from '@backend/bos/business-object-filter-type'
import { EditorHelper } from './editor-helper'
// import { ScreenBlockEditorHelper, UserTaskEditorHelper } from './screen-item-editor-helper'
import { IconPickerComponent, PrefixIconPickerComponent } from '../screen-editor/icon-picker/icon-picker.component'
import { StudioTrpcService } from '@ng-shared/lib/services/studio-trpc.service'
import { type AbstractEditorComponent } from '../abstract-editor.component'
import { type BusinessObject } from '@shared/bos/business-object'
import { LazyInitializerMap } from '@shared/data/lazy-initializer-map'

export type EditorHelpParams = BusinessObjectFilterType

@Injectable({
	providedIn: 'root',
})
export class EditorHelperService {
	private helpers = new LazyInitializerMap<string, Promise<EditorHelper>>({
		'ScreenBlock': () => import('./screen-block-editor-helper').then(mod => new mod.ScreenBlockEditorHelper(this)),
		'bpmn:UserTask': () => import('./screen-usertask-editor-helper').then(mod => new mod.UserTaskEditorHelper(this)),
	})
	private wizardDialogs = new Map<string, EditorWizardDialog[]>()
	public params: EditorHelpParams = {} as any
	injector = inject(Injector)
	public editorComponent: AbstractEditorComponent<BusinessObject>
	constructor(
		public trpcService: StudioTrpcService,
	) {
		setTimeout(async () => {
			this.wizardDialogs.set('FormBlock', [{
				label: 'Configure form elements',
				getDialogClass: () => FormEditorComponent,
			}])
			this.wizardDialogs.set('IconBlock', [{
				label: 'Pick icon',
				getDialogClass: () => IconPickerComponent,
			}])
			this.wizardDialogs.set('ButtonBlock', [{
				label: 'Pick prefix icon',
				getDialogClass: () => PrefixIconPickerComponent,
			}])
		})
	}

	async getHelper(blockType: string) {
		return this.helpers.get(blockType)
	}

	getWizardDialogs(blockType: string) {
		return this.wizardDialogs.get(blockType) ?? []
	}
}