import { DataUtil } from './../../util/data-util';
import { EntitySubset } from './../entity-subset';
import { Entity } from './../entity';
import { ExecutionContext, ExecutionLocation } from '@shared/script/execution-context'
import { BoTypeSymbol, BranchNameType, EntityCommon, EntityInfoSymbol, StaticEntityCommon } from '@shared/types'
import { EntityMethod } from '../entity-method'
import { BoReference } from '../bo-reference'
import { Parameter } from '@shared/script/parameter'
import { TypeReference } from '@shared/data/type-reference'
import { EntityProperty } from '../entity-property'
import { StaticEntity } from '../static-entity'
export class EntityUtils {
	static getClassNameForSubset(entity: Entity, subset: EntitySubset) {
		return `${entity.moduleId}__${entity.boId}` + (subset?.name ? `__${subset.name}` : '')
	}

	static getUserFriendlyClassNameForSubset(entity: Entity, subset: EntitySubset) {
		return subset.name || entity.boId
	}

	static getQualifiedClassNameForSubset(entity: Entity, subset: EntitySubset) {
		return entity.getQualifiedName() + (subset.name ? `.${subset.name}` : '')
	}

	static getAvailablePropertiesForSubset(entity: Entity, subset: EntitySubset) {
		const properties = entity.properties.filter(p => subset.itemAvailability.get(p.name) == 'y')
		for(const relation of entity.relations) {
			if(subset.itemAvailability.get(relation.name) == 'y') {
				properties.push(...relation.getPropertyDefinitions())
			}
		}
		return properties
	}

	static getMethodsForSubset(entity: Entity, subset: EntitySubset, executionContext: ExecutionContext) {
		const ctor = entity.methods.find(m => m.name == 'constructor' && !subset.name)
		const available = entity.methods.filter(m => {
			if(m.name == 'constructor') return false
			if(subset.itemAvailability.get(m.name) != 'y') return false

			return executionContext.isEntityMethodAvailable(m)
		})

		const getters = available.filter(m => m.availability == 'calculation')
		const normalMethods = available.filter(m => m.availability != 'calculation')

		return { ctor, getters, normalMethods }
	}

	static getEffectiveMethodInputs(method: EntityMethod, boRefs: BoReference[], entity: Entity, executionLocation: ExecutionLocation) {
		let inputs = [...method.inputs]

		if(method.availability == 'callableFromClient' && !method.isStatic) {
			if(executionLocation == 'server') {
				inputs.splice(0, 0, new Parameter({
					name: 'clientThis',
					type: entity.getQualifiedName(),
					defaultExpression: ''
				}))
			}
			if(executionLocation == 'client') {
				const dataStoreRefs = boRefs.filter(ref => ref.boType == 'ServerDataStore')
				inputs.splice(0, 0, new Parameter({
					name: 'serverStore',
					type: [...dataStoreRefs.map(ref => `typeof ${ref.moduleId}.${ref.boId}`), 'null'].join(' | '),
					defaultExpression: ''
				}))
			}
		}

		return inputs
	}

	static calculateTsDeclarations(entity: Entity, boRefs: BoReference[], executionContext: ExecutionContext, getStaticEntity: (typeRef: TypeReference) => StaticEntity): string[] {
		const defs: string[] = []

		defs.push(this.calculateTsDeclarationsForSubset(entity, entity.getMainSubset(), boRefs, executionContext, getStaticEntity))

		const subsetDefs = entity.subsets.map(subset => this.calculateTsDeclarationsForSubset(entity, subset, boRefs, executionContext, getStaticEntity)).join('\n')

		const typeRef = new TypeReference(entity.idType)
		defs.push(`export namespace ${entity.boId} {
			${entity.idType != 'none' ? `export type Id = NominalType<${entity.idType}, '${entity.getQualifiedName()}'>
				export const Id: typeof ${typeRef.getConstructorName()}` : ''}
			${subsetDefs}
		}`)

		return defs
	}

	private static calculateTsDeclarationsForSubset(entity: Entity, subset: EntitySubset, boRefs: BoReference[], executionContext: ExecutionContext, getStaticEntity: (typeRef: TypeReference) => StaticEntity): string {
		const className = this.getUserFriendlyClassNameForSubset(entity, subset)
		const availableProperties = this.getAvailablePropertiesForSubset(entity, subset)
		const methods = this.getMethodsForSubset(entity, subset, executionContext)
		const staticEntitiesForProperties = this.getStaticEntitiesForProperties(entity, getStaticEntity)
		
		const writer = DataUtil.createCodeBlockWriter()
		const maxLenWriter = DataUtil.createCodeBlockWriter()
		const mandatoryWriter = DataUtil.createCodeBlockWriter()

		writer.write(`export class ${className}`).block(() => {
			// writer.writeLine(`static readonly θpropertyNames: [${this.getPropertyNames(entity, availableProperties, getStaticEntity).join(', ')}]`)
			// writer.writeLine(`static readonly θsubsetNames: ${JSON.stringify(entity.subsets.map(s => s.name))}`)
			writer.writeLine(`get __type(): string`)
			writer.writeLine(`get __baseType(): string`)
			writer.writeLine(`static get __type(): string`)
			writer.writeLine(`static get __baseType(): string`)
			if(entity.idType != 'none') {
				writer.writeLine(`id: ${entity.boId}.Id`)
			}
			availableProperties.forEach(p => {
				const staticEntity = staticEntitiesForProperties.get(p)

				writer.writeLine(`${p.isStatic ? 'static ' : ''}${p.name}: ${p.type}`)
				if(staticEntity) {
					writer.writeLine(`${p.isStatic ? 'static ' : ''}'${p.name}$id': ${staticEntity.idType}`)
				}
				
				if(!p.isStatic) {
					maxLenWriter.writeLine(`${p.name}: number,`)
					mandatoryWriter.writeLine(`${p.name}(): boolean,`)
				}
			})

			const entityInfoType = `EntityInfo & {
				propertyNames: [${this.getPropertyNames(entity, availableProperties, getStaticEntity).join(', ')}]
			}`

			writer.write('static θmaxLength: ').block(() => writer.write(maxLenWriter.toString()))
			writer.write('θmaxLength: ').block(() => writer.write(maxLenWriter.toString()))
			writer.write('θisMandatory: ').block(() => writer.write(mandatoryWriter.toString()))
			writer.writeLine(`static [EntityInfoSymbol]: ${entityInfoType}`)
			writer.writeLine(`[EntityInfoSymbol]: ${entityInfoType}`)
			writer.writeLine(`static [BoTypeSymbol]: 'Entity'`)
			writer.writeLine(`[BoTypeSymbol]: 'Entity'`)

			if(methods.ctor) {
				writer.writeLine(methods.ctor.getSignature(executionContext, true))
			} else {
				writer.writeLine(`constructor(init?: Partial<${className}>)`)
			}
			methods.getters.forEach(m => writer.writeLine(
				`${m.isStatic ? 'static ' : ''}get ${m.name}(): ${m.returnType}`
			))
			methods.normalMethods.forEach(m => {
				const methodClone = new EntityMethod({
					...m,
					inputs: this.getEffectiveMethodInputs(m, boRefs, entity, executionContext.getExecutionLocation())
				})
				writer.writeLine(methodClone.getSignature(executionContext, true))
			})
			writer.writeLine(`θtoPlainObject(recursive?: boolean, includeType?: boolean): ${className}`)
			writer.writeLine(`θclone(): ${className}`)
			writer.writeLine(`updateFrom(source: Partial<${className}>): void`)
		})
		
		return writer.toString()
	}

	static getStaticEntitiesForProperties(entity: Entity, getStaticEntity: (typeRef: TypeReference) => StaticEntity) {
		const map: Map<EntityProperty, StaticEntity> = new Map()
		
		for(const property of entity.properties) {
			const typeRef = new TypeReference(property.type).forModule(entity)

			if(typeRef.kind != 'bo') continue
			if(!typeRef.boModuleId || !typeRef.boId) continue
	
			const staticEntity = getStaticEntity?.(typeRef)
			if(!staticEntity) continue

			map.set(property, staticEntity)
		}
		return map
	}

	static getPropertyNames(entity: Entity, availableProperties: EntityProperty[], getStaticEntity: (typeRef: TypeReference) => StaticEntity) {
		const staticEntitiesForProperties = this.getStaticEntitiesForProperties(entity, getStaticEntity)
		const propertyNames: string[] = availableProperties.flatMap(p => {
			if(staticEntitiesForProperties.has(p)) {
				const n = [`'${p.name}$id'`]
				if(staticEntitiesForProperties.get(p)!.hasOtherEntries) {
					n.push(`'${p.name}$other'`)
				}
				return n
			}
			return [`'${p.name}'`]
		})

		if(entity.idType != 'none') propertyNames.splice(0, 0, `'id'`)

		return propertyNames
	}

	static getNestedPropertyInfo(obj: EntityCommon, path: string, allClasses: Record<string, Record<string, any>>): {
		propertyChain: string[]
		propertyType: string
	} | null {
		const parts = path.split('.')
		const chain: string[] = []
		let propertyType = ''

		for(const [idx, part] of parts.entries()) {	
			const availableProperties = obj[EntityInfoSymbol].propertyNames
			let foundProperty = findNearest(part, availableProperties)
			if(!foundProperty) {
				foundProperty = part
			} else {
				const combinedProperty = parts.slice(idx, idx + 2).join('.') // things like 'gender.id'
				foundProperty = findNearest(combinedProperty, availableProperties)
			}
			
			if(foundProperty) {
				chain.push(foundProperty)
				propertyType = obj[EntityInfoSymbol].propertyTypes[foundProperty]
				obj = obj[foundProperty as keyof EntityCommon] as unknown as EntityCommon
				break
			} else {
				const availableRelations = {
					// ...obj?.[EntityInfoSymbol]?.oneToManyRelationships, // don't use one-to-many here!
					...obj?.[EntityInfoSymbol]?.manyToOneRelationships,
					// TODO: add oneToOne when implemented
				}
				const foundRelation = findNearest(part, Object.keys(availableRelations))
				const relationType = foundRelation ? availableRelations[foundRelation] : null
				if(relationType && foundRelation) {
					chain.push(foundRelation)
					const [moduleId, boId] = relationType.split('.')
					obj = allClasses[moduleId]?.[boId]
				} else {
					propertyType = (obj as any)?.[BoTypeSymbol] == 'StaticEntity'
						? (obj as unknown as StaticEntityCommon).__type
						: obj?.[EntityInfoSymbol]?.propertyNames[parseInt(part)]
					break
				}
			}
		}

		if(!chain.length) return null
		if(chain.length < parts.length) {
			chain.push(parts.slice(chain.length).join('.')) // add remaining "unmatched" parts (e.g. ['gender', 'id']) as a final property joined by dots ('gender.id')
		}
		return {
			propertyChain: chain,
			propertyType,
		}

		function findNearest(name: string, names: readonly string[]){
			if(names.includes(name)) return name
			const nearest = names.find(n => n.toLowerCase() == name.toLowerCase())
			return nearest
		}
	}
}