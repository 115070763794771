import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Injector, ProviderToken, signal } from '@angular/core'
import { Router } from '@angular/router'
import { BaseJsonMapper } from '@shared/data/base-json-mapper'
import { BranchNameType, Class, LanguageInfo, ModuleIdType } from '@shared/types'
import type { TypedJSON } from 'typedjson'
export class AppContext {
	static translations: Record<string, Record<string, string>> = {}
	static languages: Record<string, LanguageInfo> = {}
	static modules: Record<string, Record<string, any>> = {}
	static labelIds: string[] = []
	static injector: Injector
	static injectorSignal = signal<Injector | undefined>(undefined)

	static appModuleId: ModuleIdType
	static branchName: BranchNameType
	static defaultScreen: [string, string]
	static processId = signal('')
  
	static http: HttpClient
	static router: Router
	static location: Location
	static typedJSON: typeof TypedJSON
	static jsonMapper = new BaseJsonMapper(false, {})
	private static _knownClasses: Record<string, Class<any>> = {}
	static set knownClasses(knownClasses: Record<string, Class<any>>) { 
		this._knownClasses = knownClasses
		this.jsonMapper = new BaseJsonMapper(false, knownClasses, this.typedJSON)
	}
	static get knownClasses() { return this._knownClasses }

	static tokenId: string
  
	static set(data: Omit<typeof AppContext, 'prototype' | 'set' | 'resolve' | 'jsonMapper' | 'injectorSignal' | 'tokenId' | 'processId'>) {
		Object.assign(this, data)
		this.injectorSignal.set(this.injector)
	}

	static resolve<T>(token: ProviderToken<T>, notFoundValue?: T) {
		return this.injector.get(token, notFoundValue)
	}
}