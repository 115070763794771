import { Component, Input } from '@angular/core'
import type { AbstractEditorComponent } from '../../edit/abstract-editor.component'

@Component({
  selector: 'editor-frame',
  templateUrl: './editor-frame.component.html',
  styleUrls: ['./editor-frame.component.scss']
})
export class EditorFrameComponent {
	@Input() editor: AbstractEditorComponent<any>
}