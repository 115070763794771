import { Injectable, Injector, Signal, signal } from '@angular/core';
import { OperationLink, TRPCClientRuntime, TRPCLink, createTRPCProxyClient, createWSClient, httpBatchLink, httpLink, splitLink, wsLink } from '@trpc/client'
import { Observable, Subscriber, share, shareReplay } from 'rxjs'
import { observable, type Unsubscribable } from '@trpc/server/observable'
import { CsrfHeaderName, type Class } from '@shared/types'
import type { TRPCSubscriptionObserver } from '@trpc/client/dist/internals/TRPCClient'
import { createRetryRequestLink } from './trpc-helpers'
import { mapJsonResponseLink } from '@shared/trpc/map-json-response-link'
import { devtoolsLink } from 'trpc-client-devtools-link'
import type { AnyRouter } from '@trpc/server'
import { CsrfService } from './csrf.service'
import { csrfTokenLink } from '../trpc/csrf-token-link'
import { trpcConfig } from '@shared/shared-config'
import { toSignal } from '@angular/core/rxjs-interop'

export type Client<Router extends AnyRouter> = ReturnType<typeof createTRPCProxyClient<Router>>

@Injectable()
export class BaseTrpcService<R extends AnyRouter> {
	readonly client: Client<R>

	constructor(
		private injector: Injector,
		public csrfService: CsrfService
	) {
		const wsUrl = `${window.location.protocol.replace('http', 'ws')}//${window.location.hostname}:${window.location.port}/__api`
		const nonWsUrl = `//${window.location.hostname}:${window.location.port}/__api`
		this.client = createTRPCProxyClient<any>({
			links: [
				devtoolsLink(),
				csrfTokenLink(csrfService),
				mapJsonResponseLink,
				createRetryRequestLink(this.injector),
				
				splitLink({
					condition(op) {
						return op.type == 'subscription'
					},
					true: lazyLink(() => wsLink({
						client: createWSClient({
							url: `${wsUrl}?csrf=${encodeURIComponent(csrfService.csrfToken!)}`,
						})
					})),
					false: (trpcConfig.useBatching ? httpBatchLink : httpLink)({
						url: nonWsUrl,
						headers: () => ({
							[CsrfHeaderName]: csrfService.csrfToken,
							'content-type': 'application/json; charset=utf-8', // key 'content-type' must be lower-case as it overrides a default in node_modules/@trpc/client/src/links/internals/httpUtils.ts
						}),

					}),
				})
			],
			transformer: undefined,
		}) as any
	}

	asSignal<T>(callFn: (client: Client<R>) => Promise<T>, returnTypeClass?: Class, processResponse?: (data: T) => T | void): Signal<T | undefined> {
		const promise = callFn(this.client)
		const sig = signal<T | undefined>(undefined)
		promise.then(value => {
			const returnValue = processResponse?.(value)
			if(returnValue !== undefined) value = returnValue as T
			sig.set(value)
		}).catch(err => console.error(err))
		return sig.asReadonly()
	}

	queryAsObservable<T>(callFn: (client: Client<R>, signal: AbortSignal) => Promise<T>, returnTypeClass?: Class): Observable<T> {
		let done = false
		let promise: Promise<T>
		let abort: AbortController
		
		return new Observable<T>(subscriber => {
			abort = new AbortController()
			promise = callFn(this.client, abort.signal)
			promise.then(value => {
				// console.log('received answer')
				subscriber.next(value)
				subscriber.complete()
			}).catch(err => {
				// console.log('received error')
				subscriber.error(err)
			})
			.finally(() => done = true)

			return () => {
				if(!done) {
					abort.abort()
				}
			}
		}).pipe(
			share()
		)
	}

	/** @deprecated */
	queryAsObservable_legacy<T>(callFn: (client: Client<R>, signal: AbortSignal) => Promise<T>, returnTypeClass?: Class): Observable<T> {
		let numSubscriptions = 0
		let done = false
		let promise: Promise<T>
		let abort: AbortController
		
		return new Observable<T>(subscriber => {
			if(!numSubscriptions) {
				abort = new AbortController()
				promise = callFn(this.client, abort.signal)
				numSubscriptions++
			}
			promise.then(value => {
				subscriber.next(value)
				subscriber.complete()
			}).catch(err => subscriber.error(err))
			.finally(() => done = true)

			return () => {
				numSubscriptions--
				if(!numSubscriptions && !done) {
					abort.abort()
				}
			}
		}).pipe(
			shareReplay(1)
		)
	}

	/** @deprecated */
	subscriptionAsObservable_legacy<T, E>(callFn: (client: Client<R>, handler: Partial<TRPCSubscriptionObserver<T, E>>) => Unsubscribable): Observable<T> {
		let subscribers: Subscriber<T>[] = []
		let unsubscribable: Unsubscribable | undefined

		return new Observable<T>(subscriber => {
			subscribers.push(subscriber)
			if(!unsubscribable) {
				unsubscribable = callFn(this.client, {
					onData(value) {
						subscribers.forEach(sub => sub.next(value))
					},
					onError(err) {
						subscribers.forEach(sub => sub.error(err))
					},
					onComplete() {
						subscribers.forEach(sub => sub.complete())
					},
				})
			}

			return () => {
				subscribers.splice(subscribers.indexOf(subscriber), 1)
				if(!subscribers.length) {
					unsubscribable?.unsubscribe()
					unsubscribable = undefined
				}
			}
		})
	}

	subscriptionAsObservable<I, T extends {
		subscribe(input: I, opts: any): any
	}>(callFn: (client: Client<R>) => T, input: Parameters<T['subscribe']>[0]) {
		type O = Parameters<T['subscribe']>[1] extends Partial<TRPCSubscriptionObserver<infer O, any>> ? O : never

		return new Observable<O>(subscriber => {
			const unsubscribable = callFn(this.client).subscribe(input, {
				onData(value: O) {
					subscriber.next(value)
				},
				onError(err: any) {
					subscriber.error(err)
				},
				onComplete() {
					subscriber.complete()
				},
			})

			return () => unsubscribable.unsubscribe()
		}).pipe(
			//share(),
		)
	}

	subscriptionAsSignal<I, T extends {
		subscribe(input: I, opts: any): any
	}>(callFn: (client: Client<R>) => T, input: Parameters<T['subscribe']>[0]) {
		const observable = this.subscriptionAsObservable(callFn, input)
		return toSignal(observable)
	}

	/** @deprecated */
	subscriptionAsObservable_legacy2<I, T extends {
		subscribe(input: I, opts: any): any
	}>(callFn: (client: Client<R>) => T, input: Parameters<T['subscribe']>[0]) {
		type O = Parameters<T['subscribe']>[1] extends Partial<TRPCSubscriptionObserver<infer O, any>> ? O : never

		let subscribers: Subscriber<O>[] = []
		let unsubscribable: Unsubscribable | undefined
		return new Observable<O>(subscriber => {
			subscribers.push(subscriber)
			if(!unsubscribable) {
				unsubscribable = callFn(this.client).subscribe(input, {
					onData(value: O) {
						subscribers.forEach(sub => sub.next(value))
					},
					onError(err: any) {
						subscribers.forEach(sub => sub.error(err))
					},
					onComplete() {
						subscribers.forEach(sub => sub.complete())
					},
				})
			}

			return () => {
				subscribers.splice(subscribers.indexOf(subscriber), 1)
				if(!subscribers.length) {
					unsubscribable?.unsubscribe()
					unsubscribable = undefined
				}
			}
		})
	}
}

function lazyLink(linkFactory: () => TRPCLink<AnyRouter>): TRPCLink<AnyRouter> {
	return runtime => {
		let opLink: OperationLink<any, any> | undefined
		return (opts) => {
			if(!opLink) opLink = linkFactory()(runtime)
			return opLink(opts)
		}
	}
}
