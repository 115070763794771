import { DataUtil } from '@shared/util/data-util'
import { jsonObject, jsonMember, jsonArrayMember, toJson } from 'typedjson'

@jsonObject
export class ServiceProviderVersion {
	@jsonMember(String)
	versionName: string
	@jsonMember(String)
	comment: string

	constructor(init?: Partial<ServiceProviderVersion>) {
		DataUtil.assignCommonProperties(this, init)
	}

	getSafeName() {
		if(this.versionName.match(/^[a-z_]/)) return this.versionName
		return `v_${this.versionName}`
	}
}