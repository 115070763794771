import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { Block } from '..'
import { DataTableBlock } from './data-table-block'
import { ContentAreaBlock } from './content-area-block'
import { TabGroupBlock } from './tab-group-block'
import { TextOrExpression_assumeExpression } from '@shared/data/text-or-code'

@jsonObject({ name: 'TabGroupTabBlock' })
export class TabGroupTabBlock extends Block {
	// @jsonMember(String)
	// @EditorGeneralInput({
	// 	inputType: 'input',
	// 	order: 1,
	// 	label: 'Column name',
	// 	contentType: 'text',
	// 	elementType: 'input',
	// 	isMandatory: () => true,
	// })
	// name: string = ''

	@jsonMember(TextOrExpression_assumeExpression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		alternativeContentAreaName: 'label',
	})
	labelCode = new TextOrExpression_assumeExpression('Tab', 'T')

	@jsonMember(TextOrExpression_assumeExpression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 3,
		label: 'Tab ID',
		tooltip: () => ({ text: 'Only used if you want to programmatically set or get the selected tab' }),
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => false,
	})
	tabIdCode = new TextOrExpression_assumeExpression('', 'T')

	@jsonMember
	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 4,
		label: 'Load content lazily',
		tab: 'Advanced',
	})
	loadLazily: boolean = false


	constructor(init: Partial<TabGroupTabBlock> = {}) {
		super()
		this.init(init)
	}

	static createNewForEditor(): Block {
		return new this
	}

	getBlockName(): string {
		return 'Tab'
	}

	getEditorTitle() {
		return `${super.getEditorTitle()} ${this.labelCode.content ?? ''}`
	}

	canHaveParent(parent: Block): boolean {
		return parent instanceof TabGroupBlock
	}

	protected visitThisWithBoVisitor(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
	}

	getEditorCategory(): string {
		return 'Screen Structure'
	}
}
