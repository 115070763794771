import { Class } from '@shared/types'
import { jsonArrayMember, jsonMember, jsonObject, toJson, TypedJSON } from 'typedjson'

const TextOrCodeTypes = ['T', 'E', 'C'] as const
type TextOrCodeType = typeof TextOrCodeTypes[number]

@toJson
export class TextOrCode {
    content: string
	type: TextOrCodeType

    constructor(content: string, type: TextOrCodeType) {
        this.content = content
        this.type = type
    }

	private get quotedContent() {
		return `'${(this.content ?? '').replaceAll('\\', '\\\\').replaceAll(`'`, `\\'`)}'`
	}

	private get expressionEscapedContent() {
		return (this.content ?? '').replaceAll('"', '\\"')
	}

	getAsText() {
		switch(this.type) {
			case 'T': return this.content
			default: throw new Error(`Cannot convert ${this.type} to text`)
		}
	}

	getAsExpression() {
		switch(this.type) {
			case 'T': return this.quotedContent
			case 'E': return this.expressionEscapedContent?.trim() || 'undefined'
			default: throw new Error(`Cannot convert ${this.type} to expression`)
		}
	}

	getAsTemplateExpression() {
		return this.getAsExpression()?.replaceAll('"', '&quot;')
	}

	getAsCode() {
		switch(this.type) {
			case 'T': return this.quotedContent
			case 'E': return `return (${this.content?.trim() || 'undefined'})`
			case 'C': return this.content
		}
	}

	getAsTextOrDefault(defaultTextIfEmpty: string) {
		if(!this.content?.trim()) return defaultTextIfEmpty
		return this.getAsText()
	}

	getAsExpressionOrDefault(defaultExpressionIfEmpty: string) {
		if(!this.content?.trim()) return defaultExpressionIfEmpty
		return this.getAsExpression()
	}

	getAsTemplateExpressionOrDefault(defaultExpressionIfEmpty: string) {
		return this.getAsExpressionOrDefault(defaultExpressionIfEmpty).replaceAll('"', '&quot;')
	}

	getAsCodeOrDefault(defaultCodeIfEmpty: string) {
		if(!this.content?.trim()) return defaultCodeIfEmpty
		return this.getAsCode()
	}

	set(content: string, type?: TextOrCodeType) {
		this.content = content
		if(type) this.type = type
	}

	canSwitchType() {
		return true
	}

	getAllowedTypes(): TextOrCodeType[] {
		return ['T', 'E', 'C']
	}

	isNotEmpty() {
		return !!this.content?.trim()
	}

	toString() {
		console.warn('TextOrCode was converted to string for content:', this.content)
		return this.serialize()
	}

    serialize() {
        return `${this.type}:${this.content}`
    }

    static deserialize(value: string, ctor: Class<TextOrCode>) {
		let type: TextOrCodeType | undefined
		let content = value
		if(value?.[1] == ':' && TextOrCodeTypes.includes(value[0] as TextOrCodeType)) { // 'T:', 'E:', 'C:'
			type = value[0] as TextOrCodeType
			content = value.substring(2)
		}

		return new ctor(content, type)
    }
}

@jsonObject
export class TextOrCode_assumeText extends TextOrCode {
    constructor(content = '', type: TextOrCodeType = 'T') {
        super(content, type)
    }
}
@jsonObject
export class TextOrCode_assumeExpression extends TextOrCode {
	constructor(content = '', type: TextOrCodeType = 'E') {
		super(content, type)
	}
}
@jsonObject
export class TextOrCode_assumeCode extends TextOrCode {
    constructor(content = '', type: TextOrCodeType = 'C') {
        super(content, type)
    }
}
@jsonObject
export class TextOrExpression_assumeText extends TextOrCode {
    constructor(content = '', type: TextOrCodeType = 'T') {
        super(content, type)
    }

	getAllowedTypes(): TextOrCodeType[] {
		return ['T', 'E']
	}
}
@jsonObject
export class TextOrExpression_assumeExpression extends TextOrCode {
    constructor(content = '', type: TextOrCodeType = 'E') {
        super(content, type)
    }

	getAllowedTypes(): TextOrCodeType[] {
		return ['T', 'E']
	}
}
@jsonObject
export class Expression extends TextOrCode {
	constructor(content = '') {
		super(content, 'E')
	}

	getAllowedTypes(): TextOrCodeType[] {
		return ['E']
	}

	canSwitchType(): boolean {
		return false
	}

	serialize(): string {
		return this.content
	}
}
@jsonObject
export class Code extends TextOrCode {
	constructor(content = '') {
		super(content, 'C')
	}

	getAllowedTypes(): TextOrCodeType[] {
		return ['C']
	}

	canSwitchType(): boolean {
		return false
	}

	serialize(): string {
		return this.content
	}
}
@jsonObject
export class ExpressionOrCode extends TextOrCode {
	constructor(content = '', type: TextOrCodeType = 'E') {
		super(content, type)
	}

	getAllowedTypes(): TextOrCodeType[] {
		return ['E', 'C']
	}
}
TypedJSON.mapType(TextOrCode_assumeText, {
    serializer: (value: TextOrCode | null | undefined) => value?.serialize(),
    deserializer: (value: string) => TextOrCode.deserialize(value, TextOrCode_assumeText)
})
TypedJSON.mapType(TextOrCode_assumeExpression, {
    serializer: (value: TextOrCode | null | undefined) => value?.serialize(),
    deserializer: (value: string) => TextOrCode.deserialize(value, TextOrCode_assumeExpression)
})
TypedJSON.mapType(TextOrCode_assumeCode, {
    serializer: (value: TextOrCode | null | undefined) => value?.serialize(),
    deserializer: (value: string) => TextOrCode.deserialize(value, TextOrCode_assumeCode)
})
TypedJSON.mapType(TextOrExpression_assumeText, {
    serializer: (value: TextOrCode | null | undefined) => value?.serialize(),
    deserializer: (value: string) => TextOrCode.deserialize(value, TextOrExpression_assumeText)
})
TypedJSON.mapType(TextOrExpression_assumeExpression, {
    serializer: (value: TextOrCode | null | undefined) => value?.serialize(),
    deserializer: (value: string) => TextOrCode.deserialize(value, TextOrExpression_assumeExpression)
})
TypedJSON.mapType(Expression, {
    serializer: (value: TextOrCode | null | undefined) => value?.serialize(),
    deserializer: (value: string) => TextOrCode.deserialize(value, Expression)
})
TypedJSON.mapType(Code, {
    serializer: (value: TextOrCode | null | undefined) => value?.serialize(),
    deserializer: (value: string) => TextOrCode.deserialize(value, Code)
})
TypedJSON.mapType(ExpressionOrCode, {
    serializer: (value: TextOrCode | null | undefined) => value?.serialize(),
    deserializer: (value: string) => TextOrCode.deserialize(value, ExpressionOrCode)
})


type JoinedKeyAndPathOfValue<T extends object, FindType> = {
        [K in keyof T]: K extends string ? `${K}${JoinedPathForType<T[K], FindType>}` : never
    }
export type JoinedPathForType<T, FindType, AccumPath extends string = ''> =
    T extends FindType ? AccumPath
    : T extends Array<infer U> ? `${AccumPath}/*${JoinedPathForType<U, FindType>}`
    : T extends object ? `${AccumPath}/${JoinedKeyAndPathOfValue<T, FindType>[keyof T]}`
    : never


// interface Nested {
//     items: {
//         anotherObj: {
//             foo: string
//             code: TextOrCode
//         },
//         code: TextOrCode,
//         obj: {
//             list: {
//                 code: TextOrCode
//                 foo: string
//             }[],
//             bar: string
//         }
//     }[],
//     foo: string
// }

// let joinedNestedPath: JoinedPathForType<Nested, TextOrCode> = '/items/*/anotherObj/code'

// const executionContexts: Record<JoinedPathForType<Nested, TextOrCode>, 'client' | 'server'> = {
//     "/items/*/anotherObj/code": 'client',
//     "/items/*/code": 'server',
//     "/items/*/obj/list/*/code": 'server',
// }


// const serializer = new TypedJSON(Container)
// const container = new Container()
// container.items.push(new TextOrCode('Text: x', false))
// container.items.push(new TextOrCode('let x: string', true))

// const json = serializer.stringify(container)
// console.log(json)
// const deserialized = serializer.parse(json)
// console.log(deserialized?.items[0])
// console.log(deserialized?.items[1])
// console.log(serializer.parse('{"items":["console.log()"]}')?.items[0])

// findTextOrCodes(deserialized)
