import { FormValidationRule } from '@shared/blocks/form/form-validation-rule'
import { Entity, Screen } from '@shared/bos'
import { AccessControl } from '@shared/bos/access-control'
import { DataStore } from '@shared/bos/data-store'
import { EntityMethod } from '@shared/bos/entity-method'
import { EntityProperty } from '@shared/bos/entity-property'
import { RestrictableItemRestriction } from '@shared/bos/restrictions/restrictable-item-restriction'
import { ServerDataStore } from '@shared/bos/server-data-store'
import { ServerDataStoreProcedure } from '@shared/bos/server-data-store-procedure'
import { ServerDataStoreView } from '@shared/bos/server-data-store-view'
import { StaticEntity } from '@shared/bos/static-entity'
import { StaticEntityProperty } from '@shared/bos/static-entity-property'
import { StoreProperty } from '@shared/store/store'
import { CodeContext, CodeContextOptions } from './code-context'
import { AccessControlRestrictionContext, DataStorePropertyContext, ScreenEditorBlockPropertyDefaultContext, EntityMethodContext, PropertyDefaultContext, ScreenActionContext, CssContext, ScreenEventContext, HtmlContext, ScreenValidationContext, ScreenVariableDefaultContext, ServerDataStoreProcedureContext, ServerDataStoreViewContext, StaticEntityDynamicCodeContext, StaticEntityPropertyContext, TypeContext, ProcessEditorBlockPropertyDefaultContext, ProcessProcessVariableDefaultContext, ProcessTokenVariableDefaultContext, TriggerMailFilterContext, TriggerActionContext, JsonContext, YamlContext, ServiceIntegrationBearerTokenContext, ServiceIntegrationBasicAuthContext, ServiceProviderMethodContext } from './code-contexts'
import { Parameter } from '@shared/script/parameter'
import { VariableDefinition } from '@shared/script/variable-definition'
import { LowgileAction } from '@shared/script/lowgile-action'
import { ConfigurationItem } from '@shared/bos/configuration-item'
import { ConfigurationItemProperty } from '@shared/bos/configuration-item-property'
import { Process } from '@shared/bos/process'
import { Trigger } from '@shared/bos/trigger'
import { ServiceIntegration } from '@shared/bos/service-integration'
import { ServiceProvider } from '@shared/bos/service-provider'
import { ServiceProviderMethod } from '@shared/bos/service-provider-method'


export class CodeContextFactory {
	static create(options: CodeContextOptions<any>, forObject: any, area: string, additionalObj?: any): CodeContext | null {
		switch(area) {
			case 'type':
				return new TypeContext(options, 'any', forObject)
			case 'css':
				return new CssContext(options)
			case 'html':
				return new HtmlContext(options)
			case 'json':
				return new JsonContext(options)
			case 'yaml':
				return new YamlContext(options)
		}
			
		if(options.bo instanceof AccessControl) {
			if(forObject instanceof RestrictableItemRestriction) return new AccessControlRestrictionContext(options, forObject, additionalObj)
		}

		if(options.bo instanceof DataStore) {
			if(forObject instanceof StoreProperty) return new DataStorePropertyContext(options, forObject)
		}

		if(options.bo instanceof ConfigurationItem) {
			if(forObject instanceof ConfigurationItemProperty) return new PropertyDefaultContext(options, forObject)
		}

		if(options.bo instanceof Entity) {
			if(forObject instanceof EntityMethod) return new EntityMethodContext(options, forObject)
			if(forObject instanceof EntityProperty) return new PropertyDefaultContext(options, forObject)
		}

		if(options.bo instanceof Screen) {
			if(area == 'editorProperty') return new ScreenEditorBlockPropertyDefaultContext(options, forObject, additionalObj)

			if(forObject instanceof LowgileAction) return new ScreenActionContext(options, forObject)
			if(forObject instanceof VariableDefinition) return new ScreenVariableDefaultContext(options, forObject)
			if(forObject instanceof Parameter) return new ScreenEventContext(options, forObject, additionalObj)
			if(forObject instanceof FormValidationRule) return new ScreenValidationContext(options, forObject, additionalObj)
		}

		if(options.bo instanceof Process) {
			if(area == 'editorProperty') return new ProcessEditorBlockPropertyDefaultContext(options, forObject, additionalObj)

			if(forObject instanceof VariableDefinition) {
				if(area == 'processVariable') return new ProcessProcessVariableDefaultContext(options, forObject)
				if(area == 'tokenVariable') return new ProcessTokenVariableDefaultContext(options, forObject)
			}
		}

		if(options.bo instanceof ServerDataStore) {
			if(forObject instanceof ServerDataStoreProcedure) return new ServerDataStoreProcedureContext(options, forObject)
			if(forObject instanceof ServerDataStoreView) return new ServerDataStoreViewContext(options, forObject) 
		}

		if(options.bo instanceof ServiceIntegration) {
			if(area == 'baseUrl') return new ServiceIntegrationBearerTokenContext(options)
			if(area == 'bearerToken') return new ServiceIntegrationBasicAuthContext(options)
		}

		if(options.bo instanceof ServiceProvider) {
			if(forObject instanceof ServiceProviderMethod) return new ServiceProviderMethodContext(options, forObject)
		}

		if(options.bo instanceof StaticEntity) {
			if(forObject instanceof StaticEntityProperty) return new StaticEntityPropertyContext(options, forObject)
			if(area == 'dynamicCode') return new StaticEntityDynamicCodeContext(options)
		}

		if(options.bo instanceof Trigger) {
			if(area == 'mailFilterCode') return new TriggerMailFilterContext(options)
			if(area == 'action') return new TriggerActionContext(options)
		}

		console.error(`Could not create CodeContext for object ${forObject}`)
		return null
	}
}