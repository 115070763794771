import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, computed, inject } from '@angular/core';
import { TranslationService } from '@ng-shared/lib/services/translation.service';
import { Subscription, shareReplay, skip } from 'rxjs';
import { AppContext } from '../../app-context'
import { FrontendLanguageBridge } from '@ng-shared/lib/bridges/frontend-language-bridge'
import { BuildConfiguration } from '@shared/build/build-configuration'
import { StoreService } from '@ng-shared/lib/store/store.service'
import { DevBarService } from '@ng-shared/lib/services/dev-bar.service'
import { Router, NavigationEnd } from '@angular/router'
import { LayoutService } from '@ng-shared/lib/services/layout-service'
import { toSignal } from '@angular/core/rxjs-interop'
import { delayed$ } from '@shared/util/data-util'
import { AppTrpcService } from '../../services/app-trpc.service'
import { BoReference } from '@shared/bos/bo-reference'

@Component({
  selector: 'dev-bar',
  templateUrl: './dev-bar.component.html',
  styleUrls: ['./dev-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevBarComponent implements OnInit, OnDestroy {
  private trpc = inject(AppTrpcService)
  subscriptions: Subscription[] = []
  languages = AppContext.languages
  languageBridge = FrontendLanguageBridge
  buildStatus$ = this.trpc.subscriptionAsObservable(c => c.build.getStatus$, {
    branchName: AppContext.branchName,
    appModuleId: AppContext.appModuleId,
  }).pipe(
    shareReplay(1),
  )

  mainScreenName = ''
  isHandset = true

  constructor(
    public devBarService: DevBarService,
    public translationService: TranslationService,
    private cdRef: ChangeDetectorRef,
    private storeService: StoreService,
    private router: Router,
    private layoutService: LayoutService,
  ) {
    this.buildStatus$.pipe(
      skip(1),
    ).subscribe(buildStatus => {
      if(buildStatus.status != 'Stopped' && this.devBarService.settings.isAutoReload) {
        this.reloadApp(this.devBarService.settings.isKeepData)
      }
    })
  }

  async ngOnInit() {
    await this.devBarService.init()
    if(!this.devBarService.canUseDevBar) return

    this.mainScreenName = this.devBarService.getTopLevelScreenName()
    this.router.events.subscribe(event => {
      if(!(event instanceof NavigationEnd)) return
      setTimeout(() => this.mainScreenName = this.devBarService.getTopLevelScreenName())
    })

    this.layoutService.breakpoints$.subscribe(breakpoints => this.isHandset = breakpoints.isHandset)
    this.cdRef.detectChanges()
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

  reloadApp(keepData: boolean) {
    if(keepData) {
      this.storeService.saveToSessionStorage()
    }
    window.location.reload()
  }

  openMainScreenEditor() {
    const boRef = BoReference.fromQualifiedName(this.mainScreenName, 'Screen')
		this.devBarService.openBoEditor(AppContext.branchName, boRef)
  }

  openMainProcessEditor() {
    const boRef = BoReference.fromQualifiedName(this.devBarService.getTopLevelProcessName(), 'Process')
		this.devBarService.openBoEditor(AppContext.branchName, boRef)
  }

  async toggleFloating() {
    this.devBarService.settings.isFloating = !this.devBarService.settings.isFloating
    await this.devBarService.saveSettings()
  }
}
