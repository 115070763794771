import { Code } from '@shared/data/text-or-code'
import { Parameter } from '@shared/script/parameter'
import { DataUtil } from '@shared/util/data-util'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'

export const ServiceProviderMethodParameterTypes = {
	path: 'Path parameter',
	query: 'Query parameter',
	header: 'Header parameter',
	cookie: 'Cookie parameter',
	body: 'Request body',
}
export type ServiceProviderMethodParameterType = keyof typeof ServiceProviderMethodParameterTypes
export const ServiceProviderMethodHttpMethods = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH']
export type ServiceProviderMethodHttpMethod = typeof ServiceProviderMethodHttpMethods[number]

@jsonObject
export class ServiceProviderMethodParameter extends Parameter {
	@jsonMember(String) parameterType: ServiceProviderMethodParameterType = 'query'
}

@jsonObject
export class ServiceProviderMethod {
	@jsonMember(String)	name: string
	@jsonMember(String)	path: string
	@jsonMember(String)	httpMethod: ServiceProviderMethodHttpMethod = 'GET'
	@jsonArrayMember(String) availableInVersions: string[] = []
	@jsonMember(String)	tsReturnType: string
	@jsonArrayMember(ServiceProviderMethodParameter) parameters: ServiceProviderMethodParameter[] = []
	@jsonMember(Code) tsCode = new Code

	constructor(init?: Partial<ServiceProviderMethod>) {
		DataUtil.assignCommonProperties(this, init)
	}
	getPathRegexAsString() {
		return `/^${this.path.replace(/\{([^}]+)\}(.)?/g, (_, paramName, charAfter) => {
			const param = this.parameters.find(p => p.name == paramName)
			if(!param) throw new Error(`Parameter "${paramName}" not found in method "${this.name}"`)

			if(param.type == 'number') return `(?<${paramName}>\\d+)${charAfter ?? ''}`
			if(charAfter) return `(?<${paramName}>[^${charAfter}]+)${charAfter}`
			return `(?<${paramName}>[^/]+)`
		}).replaceAll('/', '\\/')}$/`
	}
}