import type { VariableNameType, TsTypeType, TsCodeType } from './../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { BoVisitor } from '@shared/bos/bo-visitor'
import { DataUtil } from '@shared/util/data-util'

@jsonObject
export class Parameter {
	@jsonMember(String) name!: VariableNameType
	@jsonMember(String) type!: TsTypeType
	@jsonMember(String) defaultExpression!: TsCodeType

	constructor(init?: Partial<Parameter>) {
		DataUtil.assignCommonProperties(this, init)
	}

	visit(visitor: BoVisitor, pathPrefix: (string | number)[]): void {
		visitor.visitTsCode(this.defaultExpression, this, 'input', [...pathPrefix, 'defaultExpression'], newCode => this.defaultExpression = newCode)
		visitor.visitTsCode(this.type, this, 'type', [...pathPrefix, 'type'], newType => this.type = newType)
	}

	getSignature(makeReadonly: boolean, allowDefaultAssignment = true, allowQuestionMarkModifier = false) {
		return `${this.name}${(allowQuestionMarkModifier && this.defaultExpression) ? '?' : ''}: ${makeReadonly ? `DeepReadonly<${this.type}>` : this.type}${(this.defaultExpression && allowDefaultAssignment) ? ` = ${this.defaultExpression}` : ''}`
	}

	getConversionFromString(expression: string) {
		if(this.type == 'number') return `parseFloat(${expression})`
		if(this.type == 'boolean') return `!!(${expression})`
		if(this.type == 'Date') return `new Date(${expression})`
		return expression
	}
}
