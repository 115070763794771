import { HttpClient } from '@angular/common/http';
import { map, switchMap, catchError, tap, shareReplay } from 'rxjs/operators';
import { ErrorService } from '@ng-shared/lib/services/error.service';
import { inject, Injectable } from '@angular/core';
import type { BranchNameType, BusinessObjectIdType, TsTypeType, ModuleIdType, BusinessObjectTypeType } from '@shared/types';
import { combineLatest, EMPTY, merge, Observable, of, OperatorFunction } from 'rxjs'
import { IdbService } from '@ng-shared/lib/services/idb.service'
import { StudioTrpcService } from '@ng-shared/lib/services/studio-trpc.service'

export type ParamsType = {
  moduleId: ModuleIdType;
  boType: BusinessObjectTypeType;
  boId: BusinessObjectIdType;
  branchName: BranchNameType;
};
const StaticDeclarationsIdbKey = 'Lowgile_StaticDeclarations'
const StaticDeclarationsTimestampIdbKey = 'Lowgile_StaticDeclarationsTimestamp'

@Injectable({
  providedIn: 'root',
})
export class DeclarationService {
  private idbService = inject(IdbService)

  staticTypeDeclarations$ = combineLatest([
    this.idbService.get$<string>(StaticDeclarationsIdbKey),
    this.idbService.get$<number>(StaticDeclarationsTimestampIdbKey),
    ]).pipe(
    switchMap(([declarationsJson, timestamp]) => {
      // if(declarationsJson) return of(JSON.parse(declarationsJson))

      const staticDeclarations$ = this.trpc.queryAsObservable(c => c.bo.getStaticTypeDeclarations.query({
        ifNewerThan: timestamp ?? 0,
      }))
  
      return staticDeclarations$.pipe(
        switchMap(async declarations => {
          if(declarations) {
            await this.idbService.put(StaticDeclarationsIdbKey, JSON.stringify(declarations))
            await this.idbService.put(StaticDeclarationsTimestampIdbKey, Date.now())
            return declarations
          }
          return JSON.parse(declarationsJson) as typeof declarations
        }),
        this.handleError(`Failed to load static type declarations`)
      )
    }),
    shareReplay(1),
  )
  
  constructor(
    private trpc: StudioTrpcService,
    private errorService: ErrorService,
  ) {}
  
  private handleError<T>(message: string): OperatorFunction<T, T> {
    return catchError(err => {
      this.errorService.error(message)
      console.error(err)
      return EMPTY
    })
  }
}
