export class LazyInitializerMap<K extends string | number | symbol, V> {
	private initializers = new Map<K, () => V>()
	private cache = new Map<K, V>()

	constructor(initializers?: Record<K, () => V>) {
		if(initializers) {
			for(const [key, initializer] of Object.entries(initializers) as [K, () => V][]) {
				this.initializers.set(key as K, initializer)
			}
		}
	}

	addInitializer(key: K, initializer: () => V) {
		this.initializers.set(key, initializer)
		this.cache.delete(key)
	}

	get(key: K): V | undefined {
		if(this.cache.has(key)) return this.cache.get(key)
		const initializer = this.initializers.get(key)
		if(!initializer) return undefined

		const value = initializer()
		this.cache.set(key, value)
		return value
	}

	getOrThrow(key: K): V {
		if(this.cache.has(key)) return this.cache.get(key)!

		const initializer = this.initializers.get(key)
		if(!initializer) throw new Error(`No initializer found for key ${key?.toString()}`)
		const value = initializer()
		this.cache.set(key, value)
		return value
	}
}