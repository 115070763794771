import { TRPCLink } from '@trpc/client'
import { BehaviorSubject, Observable, catchError, filter, share, take, tap } from 'rxjs'
import { observable } from '@trpc/server/observable'
import { AuthService } from './auth-service'
import { Router } from '@angular/router'
import { Injector } from '@angular/core'
import { ClientLoginResponse } from '@shared/auth/login-response'

export function createRetryRequestLink(injector: Injector) {
	const retryRequestLink: TRPCLink<any> = () => {
		const isCurrentlyRenewingTokenSubject = new BehaviorSubject(false)
		let renewLoginObservable: Observable<ClientLoginResponse> | undefined

		return ({ next, op }) => {
			return observable(observer => {

				const subscription = next(op).subscribe({
					next(envelope) {
						observer.next(envelope)
					},
					error(err) {
						if(err.message == 'WebSocket closed prematurely') {
							// most likely due to Angular compiler refresh, therefore ignore
							return
						}
						if((err.data as any)?.code == 'UNAUTHORIZED') {
							// handle 401
							if(!renewLoginObservable) {
								const authService = injector.get(AuthService)
								renewLoginObservable = authService.renewLogin$().pipe(
									tap({
										next: loginResponse => {
											console.log('renewed', loginResponse)
										},
										finalize: () => {
											// console.log('finalize')
											renewLoginObservable = undefined
										},
									}),
									share(),
								)
							}
							renewLoginObservable.subscribe({
								next(_) {
									next(op).subscribe(observer)
								},
								error(_) {
									// ignore; errors and redirect are already handled in renewLogin$
								},
								complete() {
									observer.complete()
								}
							})
						} else {
							observer.error(err)
						}
					}
				})

				return subscription
			})
		}
	}
	return retryRequestLink
}