import { AgGridModule } from 'ag-grid-angular';
import { TypeReferenceComponent } from '../components/type-reference/type-reference.component';
import { TitleBoxComponent } from './title-box/title-box.component';
import { NgModule } from '@angular/core';

import { CodeEditorComponent } from './code-editor/code-editor.component';

import { SafeHtmlPipe } from './safe-html.pipe';
import { TextOrInputComponent } from '../components/text-or-input/text-or-input.component'
import { ConfirmationDialogComponent } from '../dialogs/confirmation-dialog/confirmation-dialog.component';
import { PromptDialogComponent } from '../dialogs/prompt-dialog/prompt-dialog.component';
import { HelpComponent } from './help/help.component'
import { NgSharedModule } from '@ng-shared/lib/ng-shared.module'
import { NgxRerenderModule } from 'ngx-rerender'
import { AutosizeModule } from 'ngx-autosize'
import { NgChartsModule } from 'ng2-charts'
import { EditorFrameComponent } from './editor-frame/editor-frame.component';

@NgModule({
  declarations: [
    CodeEditorComponent,
    TitleBoxComponent,
    SafeHtmlPipe,
    TextOrInputComponent,
    TypeReferenceComponent,
    ConfirmationDialogComponent,
    PromptDialogComponent,
    HelpComponent,
    EditorFrameComponent,
  ],
  imports: [
    // CommonModule,
    // FlexLayoutModule,
    // FormsModule,
    // ReactiveFormsModule,
    // HttpClientModule,
    AgGridModule,
    NgSharedModule,
  ],
  exports: [
    CodeEditorComponent,
    TitleBoxComponent,
    SafeHtmlPipe,
    TextOrInputComponent,
    TypeReferenceComponent,
    AgGridModule,
    HelpComponent,
    NgSharedModule,
    NgxRerenderModule,
    AutosizeModule,
    NgChartsModule,
    EditorFrameComponent,
  ],
})
export class SharedStudioModule {}
