import { CommonDateBridge } from '@shared/bridges/common-date-bridge'
import { FrontendLanguageBridge } from './bridges/frontend-language-bridge'

export * from '@shared/system/common-sys'

export { FrontendUserBridge as User } from './bridges/frontend-user-bridge'
export { FrontendScreenBridge as Screen } from './bridges/frontend-screen-bridge'
export { FrontendAppBridge as App } from './bridges/frontend-app-bridge'
export { FrontendLanguageBridge as Language } from './bridges/frontend-language-bridge'
export { FrontendLayoutBridge as Layout } from './bridges/frontend-layout-bridge'
export * as Text from './bridges/frontend-text-bridge'
export { FrontendStorageBridge as Storage } from './bridges/frontend-storage-bridge'
export * as Process from './bridges/frontend-process-bridge'

export { MainScreenClass, DialogScreenClass} from '@shared/types'

export async function getHttpClient() {
	return import('axios').then(module => module.default)
}

export const Date = new CommonDateBridge(FrontendLanguageBridge)
