import { DbStandardTypeList, DbStandardTypes } from '@shared/types'
import { DataUtil } from '@shared/util/data-util'
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class ServerDataStoreEntryDbField {
	@jsonMember(Boolean)
	useCustomType:boolean  = false
	
	@jsonMember(String)
	columnType: keyof DbStandardTypes = 'string'
	@jsonMember(String)
	customColumnType: string = ''

	@jsonMember(Number)
	length: number = 50
	@jsonMember(Number)
	precision: number = 10
	@jsonMember(Number)
	scale: number = 2

	@jsonMember(Boolean)
	omit: boolean = false
	@jsonMember(Boolean)
	autoGenerate: boolean = false
	@jsonMember(Boolean)
	unique: boolean = false

	constructor(init?: Partial<ServerDataStoreEntryDbField>) {
		DataUtil.assignCommonProperties(this, init)
	}

	static defaultForType(type: string) {
		const field = new this()

		if((DbStandardTypeList as readonly string[]).includes(type)) {
			field.columnType = type as keyof DbStandardTypes
		}

		return field
	}

	hasLength() {
		return !this.useCustomType && (this.columnType == 'string' || this.columnType == 'vector')
	}

	hasPrecision() {
		return !this.useCustomType && (this.columnType == 'double')
	}

	hasScale() {
		return !this.useCustomType && (this.columnType == 'double')
	}
}