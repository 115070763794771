import { FormInputBlock } from './form-input-block';
import type { EventType, HtmlType } from './../../types'
import type { TsCodeType } from '../../types'
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'
import { ScreenEditorInput } from '../decorators/screen-editor-input'
import { BoReference } from '@shared/bos/bo-reference'
import { Parameter } from '@shared/script/parameter'
import { EditorBoRefSubtypeProperty, ScreenEditorProperty } from '../decorators/editor-property-types'
import { Screen } from '@shared/bos'
import { BlockDataSourceDefinition } from '../common/block-data-source-definition'
import { Expression, TextOrExpression_assumeExpression } from '@shared/data/text-or-code'

@jsonObject({ name: 'SearchableDropdownBlock' })
export class SearchableDropdownBlock extends FormInputBlock {
	@jsonMember(TextOrExpression_assumeExpression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 1,
		label: 'Label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string',
		editorSize: 'singleline',
		isBinding: false,
	})
	labelCode = new TextOrExpression_assumeExpression('', 'T')

	@jsonMember(Expression)
	@ScreenEditorInput({
		inputType: 'code',
		order: 2,
		label: 'Binding',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: true,
		isMandatory: () => true,
	})
	bindingCode = new Expression()

	@jsonMember
	@ScreenEditorInput<SearchableDropdownBlock>({
		order: 3,
		label: '',
		compositionObj: blocks => blocks[0].dataSource,
	})
	dataSource: BlockDataSourceDefinition = new BlockDataSourceDefinition()

	// @jsonMember
	// @ScreenEditorInput({
	// 	inputType: 'boRef',
	// 	order: 3,
	// 	label: 'Entity',
	// 	allowedBoTypes: ['Entity'],
	// 	includeImportedModules: true,
	// 	isMandatory: () => true,
	// })
	// entityRef: BoReference = new BoReference()

	// @jsonMember
	// @ScreenEditorInput<SearchableDropdownBlock>({
	// 	inputType: 'boRefSubtype',
	// 	order: 4,
	// 	label: 'Entity subset',
	// 	boRef: blocks => blocks[0].entityRef,
	// 	isMandatory: () => true,
	// })
	// entitySubsetName: string = ''

	// @jsonMember
	// @ScreenEditorInput({
	// 	inputType: 'boRef',
	// 	order: 4,
	// 	label: 'ServerDataStore',
	// 	allowedBoTypes: ['ServerDataStore'],
	// 	includeImportedModules: true,
	// 	isMandatory: () => true,
	// })
	// serverDataStoreRef: BoReference = new BoReference()

	// @jsonMember(String)
	// @ScreenEditorInput({
	// 	inputType: 'code',
	// 	order: 5,
	// 	label: 'Minimum length before searching',
	// 	codeLanguage: 'ts',
	// 	tsReturnType: () => 'number',
	// 	editorSize: 'singleline',
	// 	isBinding: false,
	// 	isMandatory: () => true,
	// })
	// minimumLengthCode?: TsCodeType = '3'

	// @jsonMember(String)
	// @ScreenEditorInput({
	// 	inputType: 'code',
	// 	order: 6,
	// 	label: 'Maximum number of results',
	// 	codeLanguage: 'ts',
	// 	tsReturnType: () => 'number',
	// 	editorSize: 'singleline',
	// 	isBinding: false,
	// 	isMandatory: () => true,
	// })
	// maximumResultsCode?: TsCodeType

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 7,
		label: 'Option label',
		codeLanguage: 'ts',
		tsReturnType: () => 'string | number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		additionalVariables: (blocks: SearchableDropdownBlock[]) => ({ $option: blocks[0].dataSource.entityRef.isFullyDefined() ? blocks[0].dataSource.entityRef.getQualifiedName(blocks[0].dataSource.entitySubsetName) : 'any' }),
		tooltip: () => ({
			text: 'Use <code>$option</code> to access each item of the options list',
			acceptHtml: true,
		}),
	})
	optionLabelCode?: TsCodeType

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 7,
		label: 'Debouncing delay (ms)',
		codeLanguage: 'ts',
		tsReturnType: () => 'number',
		editorSize: 'singleline',
		isBinding: false,
		isMandatory: () => true,
		tooltip: () => ({
			text: 'Delay in milliseconds that the app will wait after keyboard inputs until the search is executed; this helps avoid sending a request after every single typed character',
		}),
	})
	debouncingDelayCode?: TsCodeType = '250'

	@jsonMember(String)
	@ScreenEditorInput({
		inputType: 'code',
		order: 8,
		label: 'Additional find options',
		codeLanguage: 'ts',
		tsReturnType: (blocks: SearchableDropdownBlock[]) => `Sys.Types.FindManyOptions<${blocks[0]?.dataSource.entityRef.isFullyDefined() ? blocks[0]?.dataSource.entityRef.getQualifiedName(blocks[0].dataSource.entitySubsetName) : 'any'}> | null`,
		editorSize: 'normal',
		isBinding: false,
		tooltip: () => ({
			text: 'Provide additional find options if desired, e.g. to define sorting or additional filtering',
		}),
	})
	findOptionsCode?: TsCodeType = 'null'

	@ScreenEditorInput({
		inputType: 'checkbox',
		order: 10,
		label: 'Allow clearing value',
	})
	@jsonMember(Boolean) allowClearing: boolean = true
	
	get allowedEventTypes(): EventType[] { return [
		{ name: 'optionSelected', parameters: [new Parameter({ name: '$option', type: `${this.dataSource.entityRef.getQualifiedName()} | null`, defaultExpression: '$event' })] },
	]}

	producePreview(): HtmlType {
		return `<select tabindex="-1"><option>${this.exampleText || 'Option'}</option></select>`
	}

	getEditorCategory(): string {
		return 'Additional Form Fields'
	}
}
