<ng-container *ngIf="devBarService.canUseDevBar && devBarService.settings">
    <div
        *ngIf="buildStatus$ | async as buildStatus"
        class="dev-bar flexRow"
        [style.background]="buildStatus.bgColor"
        [style.border-bottom]="devBarService.environmentSettings ? ('0.5em solid ' + devBarService.environmentSettings.bgColor) : ''"
        [class.floating]="devBarService.settings.isFloating"
        [class.collapsed]="devBarService.isCollapsed"
        (mouseleave)="devBarService.isCollapsed = devBarService.settings.isFloating"
    >
        <div *ngIf="devBarService.settings.isFloating" class="float-bar" (mouseenter)="devBarService.isCollapsed = false"></div>
        <div>
            <input type="checkbox" [(ngModel)]="devBarService.settings.isAutoReload" (ngModelChange)="devBarService.saveSettings()"> Auto-reload
        </div>
        <div>
            <input type="checkbox" [(ngModel)]="devBarService.settings.isKeepData" (ngModelChange)="devBarService.saveSettings()"> Keep data on auto-reload
        </div>
        <div>
            <button (click)="reloadApp(true)">Reload keeping data</button>
        </div>
    
        <ng-container *ngIf="!isHandset">
            <div>
                <div>
                    <mat-checkbox
                        [(ngModel)]="languageBridge.editMode"
                    >Translation Mode</mat-checkbox>
                </div>
                <div>
                    <select [(ngModel)]="languageBridge.language">
                        <option *ngFor="let language of languages | keyvalue" [value]="language.key">
                            {{ language.value.displayName }}
                        </option>
                    </select>
                </div>
            </div>
            <div>
                <button (click)="openMainScreenEditor()">Edit Screen {{mainScreenName}}</button>
            </div>
            <div *ngIf="devBarService.getTopLevelProcessName()">
                <button (click)="openMainProcessEditor()">Edit Process {{devBarService.getTopLevelProcessName()}}</button>
            </div>
        
            <div style="flex: 1 0 0"></div>
        
            <div class="flexColumn">
                <div class="environment-public-name">{{devBarService.environmentSettings?.publicName ?? ''}}</div>
                <div style="flex: 1 0 0"></div>
                <div class="environment-short-name">{{devBarService.environmentSettings?.shortName ?? ''}}</div>
            </div>
    
            <div class="expand-box">
                <mat-icon (click)="toggleFloating()">{{ devBarService.settings.isFloating ? 'expand_more' : 'expand_less' }}</mat-icon>
            </div>
        </ng-container>
    </div>
</ng-container>