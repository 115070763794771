import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson } from 'typedjson'
import { BoReference } from './bo-reference'
import { DataUtil } from '@shared/util/data-util'
import { BoVisitor } from './bo-visitor'
import { Expression } from '@shared/data/text-or-code'
import { type ServiceIntegrationAvailabilityOnClientType } from '@shared/types'

export const ServiceIntegrationTypes = {
	mail: 'E-Mail',
	openApi: 'OpenAPI',
	soap: 'SOAP',
	graphql: 'GraphQL',
	sap: 'SAP',
} as const
export type ServiceIntegrationType = keyof typeof ServiceIntegrationTypes

export const ServiceIntegrationContentTypes = {
	json: { name: 'JSON', mimeType: 'application/json' },
	yaml: { name: 'YAML', mimeType: 'application/x-yaml' },
} as const
export type ServiceIntegrationContentType = keyof typeof ServiceIntegrationContentTypes
export const ServiceIntegrationAuthenticationTypes = {
	none: 'None (anonymous)',
	bearerToken: 'Bearer token',
	basic: 'Basic (username and password)',
} as const
export type ServiceIntegrationAuthenticationType = keyof typeof ServiceIntegrationAuthenticationTypes
export const ServiceIntegrationAvailabilityOnClientTypes: Record<ServiceIntegrationAvailabilityOnClientType, string> = {
	none: 'None (only usable on server-side)',
	builtin: 'Built into client app (use only when intending to call ServiceIntegration directly from client-side)',
}

@jsonObject({ name: 'ServiceIntegration' })
export class ServiceIntegration extends BusinessObject {
	@jsonMember(String)
	interfaceType: ServiceIntegrationType = 'mail'
	@jsonMember
	credentialRef?: BoReference<'ConfigurationItem'>

	@jsonMember(String)
	schemaUrl?: string
	@jsonMember(String)
	schemaContent: string = ''
	@jsonMember(String)
	schemaContentType: ServiceIntegrationContentType = 'json'
	@jsonMember(Expression)
	baseUrlExpression = new Expression()
	@jsonMember(String)
	authenticationType: ServiceIntegrationAuthenticationType = 'none'
	@jsonMember(Expression)
	authenticationExpression = new Expression()
	@jsonMember(String)
	availabilityOnClient: ServiceIntegrationAvailabilityOnClientType = 'none'
	
	constructor(init?: Partial<ServiceIntegration>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	visit(visitor: BoVisitor): void {
		if(this.credentialRef) visitor.visitBoReference(this.credentialRef, ['credentialRef'])
	}

	getServiceUrlOptions(): string[] {
		if(!this.schemaContent?.trim()) return []

		const schemaObj = JSON.parse(this.schemaContent)
		const urls = schemaObj?.servers?.map((server: any) => server.url) ?? []
		return urls
	}

	getClassNames() {
		return [this.getQualifiedName()]
	}
}