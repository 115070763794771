import { BusinessObject } from './business-object'
import { jsonObject, jsonMember, jsonArrayMember, toJson, jsonMapMember } from 'typedjson'
import { ServerDataStoreEntry } from './server-data-store-entry'
import { RestrictableItem } from './restrictions/restrictable-item'
import { Parameter } from '@shared/script/parameter'
import { BoVisitor } from './bo-visitor'
import { DataUtil } from '@shared/util/data-util'
import { ServerDataStoreProcedure } from './server-data-store-procedure'
import { ServerDataStoreView } from './server-data-store-view'
import { BoReference } from './bo-reference'

export const ServerDataStoreTypes = {
	db: 'DataBase',
	file: 'File-based',
} as const

export const ServerDataStoreDbTypes = {
	postgres: 'PostgreSQL',
	mssql: 'MS SQL',
	mysql: 'MySQL',
	sqlite: 'SQLite',
	oracle: 'Oracle',
} as const

@jsonObject({ name: 'ServerDataStore' })
export class ServerDataStore extends BusinessObject {
	@jsonMember(String)
	storeType: keyof typeof ServerDataStoreTypes = 'db'
	@jsonMember(Boolean)
	useSystemDatabase: boolean = true
	@jsonMember(String)
	dbType!: keyof typeof ServerDataStoreDbTypes
	@jsonMember(String)
	dbSchemaName: string = 'solution'
	@jsonMember
	dbCredentialConfigurationItem?: BoReference

	@jsonMember(Boolean)
	createStaticEntityTables: boolean = false

	@jsonArrayMember(ServerDataStoreEntry)
	entries: ServerDataStoreEntry[] = []
	@jsonArrayMember(ServerDataStoreProcedure)
	procedures: ServerDataStoreProcedure[] = []
	@jsonArrayMember(ServerDataStoreView)
	views: ServerDataStoreView[] = []

	readonly affectsDb: boolean = true

	constructor(init?: Partial<ServerDataStore>) {
		super()
		DataUtil.assignCommonProperties(this, init)
	}

	getRestrictableItems(): RestrictableItem[] {
		if(this.storeType == 'db') {
			const restrictables: RestrictableItem[] = []
			for(const entry of this.entries) {
				switch(entry.boType) {
					case 'Entity':
						restrictables.push(new RestrictableItem({
							itemName: entry.getQualifiedName(),
							functionName: `access_${entry.moduleId}__${entry.boId}`,
							parameters: [
								new Parameter({ name: 'operation', type: `'read' | 'create' | 'update' | 'delete'` }),
								new Parameter({ name: 'accessController', type: `SdsEntityAccessController<typeof ${entry.getQualifiedName()}>` }),
							],
							contextImports: {
								'SdsEntityAccessController': '@shared/acl/sds-entity-access-controller',
							},
							defaultCode: `\taccessController.allowServer()\n\tif(Sys.User.currentUser?.hasEffectivePermission('Module.WhicheverPermissionIsRequired')) {\n\t\taccessController.allowServerAndClient()\n\t}\n\tswitch(operation) {\n\t\tcase 'read':\n\t\tcase 'create':\n\t\tcase 'update':\n\t\tcase 'delete':\n\t\t\t//accessController.allowServerAndClient()\n\t\t\tbreak\n\t}`,
						}))
						break

					default:
						// ignore
				}
			}
			return restrictables
		}

		return []
	}

	includesEntry(entry: ServerDataStoreEntry): boolean {
		return this.entries.some(e => e.matches(entry))
	}

	getClassNames(): string[] {
		return [this.getQualifiedName()]
	}

	visit(visitor: BoVisitor): void {
		this.entries.forEach(entry => visitor.visitBoReference(entry, ['entries']))
	}
}